.prescription-preview-container {
  margin: -140px 0 100px 0;
  .prescription-preview-button-row {
    display: flex;
    justify-content: space-between;
    width: 700px;
  }
  .prescription-preview-go-back {
    margin-top: -20px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
}
.prescription-preview-container-checkbox {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
  input {
    margin: 0 8px 0 0;
    width: 14px;
    height: 14px;
    outline: none;
    border: 0;
  }
  p {
    margin: 0;
  }
}
