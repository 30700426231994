.request-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.medical-history-button {
  width: 100% !important;
  margin-top: 20px;
  color: #22548a;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 18px;
  background-color: #f4f6f9;
  border-radius: 10px;
  padding: 7px 150px;
  transition: all 0.5s linear;
  color: #fff !important;
  background-color: #22548a !important;
}
.medical-history-button:disabled {
  color: #6c6c6c;
}
.request-sent-text {
  margin-top: 10px;
  font-weight: 600;
  color: #379f8e;
}
