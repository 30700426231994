.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22548a;
  height: 60px;
  width: 33.333333%;
  font-weight: 600;
  font-size: 20px;
  color: white;
  cursor: pointer;
}
.non-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f9;
  width: 33.333333%;
  height: 60px;
  font-weight: 600;
  font-size: 20px;
  color: #22548a;
  cursor: pointer;
  border-right: 3px solid #cdd7e2;
}
.locked {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  width: 33.333333%;
  height: 60px;
  font-weight: 600;
  font-size: 20px;
  color: #6c6c6c;
  border-right: 3px solid #cdd7e2;
}
