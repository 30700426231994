.product-tag {
  padding: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  .product-name {
    margin-right: 5px;
  }
}
.recommended {
  background-color: #0085ff;
}
.restricted {
  background-color: #a33b3b;
}
