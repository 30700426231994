.onelineParent {
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
  border-spacing: 10px; /*Optional*/
}
.oneline {
  display: table-cell;
  //background-color: red; /*Optional*/
}
.rightarrow {
  padding-left: 25%;
}
