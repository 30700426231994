.class1:hover {
  cursor: pointer !important;
  padding: 10px 70px !important;
  color: white !important;
  background-color: #154166 !important;
}

/* Spinner implementation */
.loader-cm {
  text-align: center;
  margin-left: auto;
  font-size: 20px;
  color: #154166;
}
.Loader-box {
  width: 50px;
  display: inline-block;
  margin-left: 20px;
}
.inln-blk {
  display: inline-block;
}
