.send-prescription-button {
  width: 100%;
  padding: 12px 0;
  border-radius: 14px;
  border: none;
  outline: none !important;
  font-size: 18px;
  color: white;
  font-weight: 600;
  background-color: #379f8e;
}
.send-prescription-button:disabled {
  background-color: #ebeced;
  color: #6c6c6c;
}
.send-prescription-email,
.send-prescription-phone {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 18px;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}
.send-prescription-phone {
  padding: 0 15px;
}
.email-tag {
  background: rgb(240, 242, 246);
  border-radius: 10px;
  color: "darkgrey";
  margin: 5px;
  padding: 5px;
  width: fit-content;
}
.email-search-combobox {
  margin-top: 10px;
  margin-bottom: 10px;
}
