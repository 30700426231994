.general-modal {
  scrollbar-width: none !important;
  padding: 15px 20px 20px 20px;
  border-radius: 30px;
  .subheading {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .val {
    margin-right: 20px;
  }
  .modal-open {
    overflow: scroll;
  }
  .modal-title {
    font-size: 32px;
    font-weight: 600;
    margin: 5px 0 10px 20px;
  }
  .close-button {
    width: 30px;
    height: 30px;
    // top:5px;
    cursor: pointer;
  }
  .timeline-date {
    margin-left: -10px;
    font-size: 18px;
    font-weight: 400;
    color: white;
    background-color: #379f8e;
    width: fit-content;
    padding: 2px 17px 2px 10px;
    border-radius: 0 50px 50px 0;
  }
  .timeline-image {
    margin: 10px;
    border-radius: 15px;
    width: 250px;
    height: 250px;
    object-fit: contain;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
}
.imageTimelineModalContainer {
  margin-top: 20px;
  height: 650px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none !important;
}
