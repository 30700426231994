@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
:root {
  --primary-font: "Inter", sans-serif;
  --blue-dark: #081e89;
  --blue: #2668ea;
  --cyan: #57b5cd;
  --purple: #7270e2;
  --red: #dd2b5e;
  --yellow: #feb702;
  --neutral-b: #474857;
  --neutral-w: #f0f1f6;
  --neutral-g: #edf2f7;
  --light: 300;
  --regular: 400;
  --semi-bold: 500;
  --bold: 600;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--primary-font);
  // border: solid 1px red;
}

html {
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  max-width: 100%;
}

body {
  font-weight: var(--regular);
  color: var(--neutral-b);
}

/*
    CSS Methodology: BEM (block__element--modifier)
    Ref: http://getbem.com/
*/

.button {
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  background: none;
  border: 0;
  font-size: 0.75rem;
  font-weight: 600;
  outline: 0;
  transition: 60ms;
}

.button:hover {
  filter: brightness(105%);
}

.button:active {
  transform: scale(0.9);
}

.button--primary {
  background: var(--cyan);
  color: var(--neutral-w);
}

.button--secondary {
  background: var(--blue-dark);
  color: var(--neutral-w);
}

.button--red {
  background: var(--red);
  color: var(--neutral-w);
}

.button--icon {
  background: var(--neutral-g);
}

.input {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--neutral-g);
  outline: 0;
  transition: 60ms;
  caret-color: var(--cyan);
}

.input:focus {
  border: 1px solid var(--cyan);
}

.label {
  font-weight: var(--bold);
}

// Utility Classes
.centered {
  display: grid;
  place-items: center;
}

.spaced-l > * + * {
  margin-left: 1rem;
}

.spaced-t > * + * {
  margin-top: 1rem;
}

.nowrap {
  white-space: nowrap;
}

.scroll-h {
  overflow-x: scroll;
}

// Fixes
.admin-page-container {
  padding: 1rem;
}

.admin-page-container > * + * {
  margin-top: 3rem;
}

.admin-nav-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.dategroup {
  display: flex;
}

.form {
  margin: 0.5rem;
}

.pagination {
  display: flex;
  font-weight: 300;
  margin-top: 1rem;
  align-self: center;
}

.pagination > * + * {
  margin-left: 0.5rem;
}

.arrow {
  background: var(--neutral-g);
}

.page-index {
  display: flex;
  align-items: center;
}

.table-r {
  cursor: pointer;
}

.table-r:hover {
  background: var(--neutral-g);
}

.table-h,
.table-d {
  padding: 0.5rem 1rem;
  text-align: center;
  border: solid 1px var(--neutral-g);
  white-space: nowrap;
}

.table-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.table-container > table {
  border-left: solid 1px var(--neutral-g);
  border-right: solid 1px var(--neutral-g);
}

.table-title {
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row-box {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

.table-row-child {
  width: 30%;
}
.searchBoxInput {
  padding-left: 5px;
  margin-left: 10px;
}
.docSchedule td {
  padding: 0px 3px;
}
.statusLineRed {
  display: inline-block;
  width: 8px !important;
  border-top: 2px solid red;
}
.statusLineGreen {
  display: inline-block;
  width: 8px !important;
  border-top: 2px solid green;
}
.brand-filter-button {
  border-radius: 4px;
  margin: 12px 4px !important;
  padding: 10px 15px;
  cursor: pointer;
}
.brand-filter-all {
  border: 2px solid rgb(120, 120, 120);
  color: rgb(120, 120, 120) !important;
}
.brand-filter-all.active {
  background-color: rgb(30, 30, 30);
  border: 2px solid rgb(30, 30, 30);
  color: white !important;
}
.brand-filter-mm {
  border: 2px solid rgb(94, 147, 189);
  color: rgb(94, 147, 189) !important;
}
.brand-filter-mm.active {
  background-color: rgb(94, 147, 189);
  color: white !important;
}
.brand-filter-bw {
  border: 2px solid #665099;
  color: #665099 !important;
}
.brand-filter-bw.active {
  background-color: #665099;
  color: white !important;
}
.bottom-button-container {
  border-top: 1px solid rgb(70, 70, 70);
  box-shadow: 0px -20px 40px rgb(0, 0, 0);
  z-index: 1;
}
.bulk-ooc-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}
.bulk-ooc-button {
  margin: 16px 12px;
}
.modal-ooc-button {
  margin: 12px 0px;
}
.bulk-ooc-modal {
  max-width: 1150px !important;
}

.MuiPopover-paper {
  width: max-content !important;
}
