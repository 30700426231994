.view-history-expand-icon {
  width: 70px;
  padding-top: 7px;
  height: auto;
}

.view-history-custom-accordion {
  border: 1px solid lightgray;
  border-radius: 20px !important;
  width: 70vw;
  .view-history-accordion-title {
    font-size: 26px;
    font-weight: 600;
    margin: 15px 0px 15px 20px;
    color: #22548a;
  }
  .view-history-accordion-detail {
    padding: 0 35px;
  }
}
.medicalHistory__view-history-button-container {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .view-history-medical-history-button {
    color: #fff;
    background-color: #22548a;
    transition: all 0.5s linear;
    border: none;
    outline: none;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    padding: 7px 15px 7px 15px;
    margin: 0;
    width: max-content !important;
    white-spacing: nowrap !important;
    height: 40px;
  }
  .view-history-medical-history-button:disabled {
    background-color: #f4f6f9;
    color: #6c6c6c;
  }
}
.modal-content {
  border-radius: 20px;
  background-color: #f9fafb !important;
  width: fit-content !important;
}

.view-history-title {
  font-weight: 600 !important;
  margin-left: 10px !important;
  font-size: 32px;
  margin-bottom: 15px;
}
.view-history-header-row {
  margin: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  .view-history-close-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.view-history-subheading {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 10px;
}
.view-history-concerns-content {
  display: flex;
}
.view-history-category {
  width: 100%;
  padding-right: 15px;
  font-size: 24px;
  font-weight: 500;
}
.view-history-chief-complaint {
  width: 100%;
  padding-right: 10px;
  font-size: 24px;
  font-weight: 500;
}
.view-history-severity {
  font-size: 24px;
  padding-right: 15px;
  font-weight: 500;
}
.view-history-onset {
  font-size: 24px;
  font-weight: 500;
  width: 100% !important;
  .view-history-onset-date-container {
    display: flex;
    justify-content: space-between;
    .view-history-onset-date {
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 400;
      padding: 10px 16px;
      // width: fit-content !important;
      margin: 7px 0 10px 0;
      overflow: hidden;
      background-color: #f4f6f9;
      border-radius: 10px;
      border: none;
      outline: none;
    }
  }
}
.view-history-value-box {
  font-size: 18px;
  padding: 10px 10px;
  width: 100% !important;
  margin: 7px 0 20px 0;
  overflow: scroll;
  background-color: #f4f6f9;
  border-radius: 10px;
  border: none;
  outline: none;
}
.view-history-diagnosis_card {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  input {
    border: 1px solid black;
  }
  .view-history-selected-radio {
    margin: 0 20px 0 5px;
    font-weight: 500;
    color: #6c6c6c;
  }
  .view-history-unselected-radio {
    margin: 0 20px 0 5px;
    font-weight: 500;
    color: #c8c8c8;
  }
  textarea {
    height: 140px;
    border-radius: 12px;
    padding: 10px 14px;
    background: #f4f6f9;
    resize: none;
    border: 0;
    width: 100%;
  }
}
.view-history-past-medical-diagnosis {
  width: 100%;
  display: flex;
  margin-top: 15px;
  .view-history-past-medical {
    width: 50% !important;
    display: flex;
    flex-direction: column;
    .view-history-past-medical-content {
      width: 100% !important;
      padding-right: 15px;
      .view-history-past-medical-subheadings {
        font-size: 24px;
        font-weight: 500;
        padding-right: 10px;
      }
    }
  }
}
.view-history-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 20px;
  }
}
