.margin-30 {
  margin: 30px;
}
.OrderTable {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
}
.OrderTable__title {
  margin-left: 50px;
  font-size: 32px;
  font-weight: 700;
}
.OrderTable__subtitle {
  margin-left: 50px;
  font-size: 20px;
  font-weight: 400;
}
