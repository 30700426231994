.header-buttons__wrapper > button {
  width: 110px;
  height: 40px;
  border-radius: 12px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.header-buttons__wrapper {
  flex: 0.3;
  display: flex;
  justify-content: space-between;
  max-width: 380px;
}
.phone-call__button {
  background-color: #22548a;
  margin-right: 10px;

  &:disabled {
    background-color: grey;
  }
}

.video-call__button {
  background-color: #379f8e;
  font-family: "Sofia Pro";
  &:disabled {
    background-color: grey;
  }
}
.phone-call__button {
  font-family: "Sofia Pro";
}
.ongoing-phone-call,
.ongoing-video-call {
  width: 110px;
  height: 40px;
  border-radius: 12px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  background-color: #f4f6f9;
  color: #22548a;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.send-video-link__button {
  color: #379f8e !important;
  background: #ffffff;
  font-family: "Sofia Pro";
  margin-left: 10px;
  padding: 0 10px;
  width: 130px !important;
  &:hover {
    border: 1px solid #379f8e;
  }
}
