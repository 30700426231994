.dashboardInfo {
  padding: 10px 10px 20px;
  background-color: rgb(231, 238, 235);
  font-weight: 600;
  font-size: calc(14px + 0.2vw);
}
.dashboardInfo > div:nth-child(1) {
  color: rgb(0, 183, 255);
  display: flex;
  justify-content: space-between;
}
.dashboardInfo > div:nth-child(2) {
  color: gray;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.infoCard {
  border-radius: 8px;
  background-color: white;
  margin: 20px 0;
  box-shadow: 0 0 5px 5px grey;
  display: grid;
  grid-template-columns: 7fr 1fr;
}
.infoCard > div:nth-child(1),
.infoCard > div:nth-child(2) {
  grid-column: 1 / span 7;
  display: flex;
  justify-content: space-between;
  padding-right: 9px;
  margin: 5px 0 0 9px;
}
.infoCard > div:nth-child(2) {
  padding-bottom: 9px;
  border-bottom: 2px solid grey;
}
.infoCard > div:nth-child(1) > div:nth-child(1),
.infoCard > div:nth-child(2) > div:nth-child(1) {
  color: blue;
}
.infoCard > div:nth-child(2) > div {
  display: flex;
  flex-wrap: wrap;
}
.infoCard > div:nth-child(2) > div > div {
  white-space: nowrap;
}
.infoCard > div:nth-child(3) {
  grid-column: 1 / span 7;
  display: grid;
  padding: 25px 0 25px 9px;
  color: blue;
  grid-template-columns: repeat(auto-fit, minmax(14.28%, 1fr));
}

.infoCard > div:nth-child(3) > div::first-line {
  font-size: 30px;
  font-weight: bolder;
}
.infoCard > div:nth-child(4) {
  grid-column: 8;
  grid-row: 1 / span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.infoCard > div:nth-child(3) > div {
  padding: 10px;
}
.infoCard > div:nth-child(3) > div:nth-child(1) {
  padding-left: 0px;
  padding-right: 0px;
}
// .infoCard > div:nth-child(3) > div:nth-child(2) {
//   margin-right: 1.2em;
//   border-right: 2px solid grey;
// }
.blue {
  background-color: blue;
}
.yellow {
  background-color: yellow;
}

.infoCard > div:nth-child(3) > div:nth-child(1)::first-line {
  color: green;
}
.infoCard > div:nth-child(3) > div:nth-child(2)::first-line {
  color: rgb(190, 190, 17);
}
.infoCard > div:nth-child(3) > div:nth-child(3)::first-line {
  color: rgb(73, 158, 73);
}
.infoCard > div:nth-child(3) > div:nth-child(4)::first-line {
  color: blue;
}
.infoCard > div:nth-child(3) > div:nth-child(5)::first-line,
.infoCard > div:nth-child(3) > div:nth-child(6)::first-line {
  color: rgb(160, 5, 160);
}
