.history2-sec-box {
  overflow-y: scroll;
  width: 50%;
  max-height: 400px;
  z-index: 99;
  background-color: #f2f6f8;
  top: 0;
  left: 0;
  padding: 20px;
  .hsb--back {
    font-size: 24px;
  }
}
.raisedbox {
  font-size: small;
  padding: 10px;
  border: 0.5px solid #676a6c;
  box-shadow: -1px 1px #676a6c, -1px 2px #676a6c, -1px 2px #676a6c,
    -2px 2px #676a6c, -1px 1px #676a6c;
}
.historybox {
  width: 100%;
  font-style: #2e5385;
  background-color: white;
  padding: 10px;
  margin: 10px;
  height: auto;
}

.toggleButton {
  position: relative;
  // left:825px;
  float: right;
}
