.Updatebutton {
  width: 50%;
  background: rgb(35, 85, 138);
  color: #fff;
  font-weight: 600;
}

.select {
  width: 260px;
  height: 40px;
  line-height: 50px;
  -webkit-appearance: menulist-button;
  -moz-appearance: none;
}
.textarea {
  width: 260px;
  height: 200px;
}
