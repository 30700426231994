.add-product-container {
  margin: 30px;
  .cancel-button {
    background-color: #f2f3f4;
    border: none;
    outline: none !important;
    padding: 8px 17px;
    border-radius: 10px;
    opacity: 0.7;
    font-weight: 600;
    width: 200px;
    margin-right: 15px;
  }
  .add-button {
    background-color: #379f8e;
    border: none;
    outline: none !important;
    padding: 8px 17px;
    border-radius: 10px;
    width: 200px;
    color: white;
    font-weight: 600;
  }
}
.full-width {
  width: 100%;
}
.add-product-button {
  background-color: #379f8e;
  border: none;
  outline: none !important;
  padding: 8px 17px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  margin-left: 20px;
}
