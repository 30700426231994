.header__card {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.2);
}
.header__wrapper {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 60px 0px 50px;
  background-color: white;
  width: 100%;
  height: 60px;
  z-index: 10;
  font-family: "Sofia Pro";
  box-sizing: "border-box";
}
.header-wrapper-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 10px;
}
