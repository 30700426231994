@import "../../base.scss";
.patientDetails {
  margin: 30px;
  margin-top: 10px;
  border: 1px solid #f6f6f6;
  padding: 20px 30px 20px 30px;
  border-radius: 15px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  font-family: "Sofia Pro";
  .input-field {
    border: none;
    outline: none;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 7px 10px 7px 10px;
    font-weight: 400;
    width: 100%;
  }
  .input-overflow {
    overflow: hidden;
    resize: none;
  }
  .header-row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 10px;
    .column {
      display: flex;
      flex-direction: column;
    }
    .avatar {
      margin-right: 20px;
      width: 75px;
      height: 75px;
    }
    .name {
      font-size: 28px;
      font-weight: 600;
      margin-right: 10px;
      margin-bottom: 5px;
      font-family: "Sofia Pro";
    }
    .new-patient {
      margin-top: -10px;
      height: auto;
    }
    .call-type-tag {
      display: flex;
      justify-content: center;
      border-radius: 10px;
      font-weight: 500;
      margin-left: -10px;
      padding: 3px 10px 3px 10px;
      border: 1px solid #379f8e;
      color: #379f8e;
      margin-right: 8px;
    }
    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-weight: 500;
      font-size: 12px;
      color: #9a521c;
      border: 1px solid #9a521c;
      border-radius: 6px;
      width: fit-content;
      padding: 3px 10px 3px 10px;
      background-color: #fef3ea;
      font-family: "Sofia Pro";
    }
  }
  .edit-button {
    color: #22548a;
    border: 1px solid #22548a;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    background-color: #f4f6f9;
    border-radius: 10px;
    padding: 7px 30px 7px 25px;
    transition: all 0.2s linear;
    position: absolute;
    right: 50px;
    font-family: "Sofia Pro";
  }
  .save-button {
    color: white;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    background-color: #22548a;
    border-radius: 10px;
    padding: 7px 30px 7px 25px;
    transition: all 0.2s linear;
    position: absolute;
    right: 50px;
    font-family: "Sofia Pro";
  }
  .col {
    padding-right: 10px;
  }
  .patient-detail {
    height: 80px;
    overflow: hidden;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    select {
      padding: 8px 30px 8px 14px;
      width: 100%;
      background: $select-background-color;
      border-radius: 12px;
      outline: 0;
      border: 0;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("../../asset/selectIcon.svg");
      background-position: right;
      background-repeat: no-repeat;
      background-size: 32px 7px;
      option {
        cursor: pointer;
        color: $option-color;
        font-weight: $option-font-weight;
      }
    }
    .subheading {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 3px;
    }
    .value {
      margin-top: 7px;
      font-size: 16px;
      font-weight: 600;
    }
    .copy-button {
      cursor: pointer;
    }
  }
  .section-break {
    width: 100%;
    border-top: 1px dashed #b5b5b5;
    margin-bottom: 20px;
    font-family: "Sofia Pro";
  }
}
