$ARROW_ICON_URL: url("https://ik.manmatters.com/mosaic-wellness/image/upload/v1652377914/dochub/rx-writer/Vector_8.png");

.left-section {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: white;
  width: max-content;
  border-radius: 12px;
  .date-range-section {
    display: flex;
  }
  .quick-option-section {
    display: flex;
  }
  .radio-input {
    margin-right: 8px;
    margin-left: 20px;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #22548a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &:checked {
      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #22548a;
        border-radius: 50%;
      }
    }
  }
  .date-input {
    margin: 0px 10px;
    margin-right: 20px;
    border: 0.5px solid #c4c4c4;
    padding: 4px 12px;
    width: 180px;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    color: rgba(184, 184, 184, 1);
    &:focus {
      outline: 1px solid #22548a;
    }
  }
  .date-input::-webkit-calendar-picker-indicator {
    color: transparent;
    z-index: 1;
    background-image: $ARROW_ICON_URL;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 12px;
    cursor: pointer;
    height: 10px;
  }
  .date-group {
    display: flex;
    align-items: center;
  }
  .option-group {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .quick-option-section {
    margin-right: 15px;
    .search-button {
      border: none;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;

      padding: 8px 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      background-color: #22548a;
      border-radius: 10px;
    }
  }
}

.input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #22548a;
}
