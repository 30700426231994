.latest-images {
  padding-top: 0px !important;
  padding-bottom: 30px !important;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  .images-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    .patient-image {
      margin: 12px 5px;
      box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 100px;
      height: 100px;
      object-fit: contain;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
