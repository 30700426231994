.recommendation-builder-container {
  padding: 40px 30px;
  background-color: #c4c4c4;
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .brands {
      margin: 0;
      .brand-value {
        margin-left: 20px;
        margin-right: 5px;
      }
    }
  }
  .header__searchBox {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 100%;
      border: none;
      outline: none;
      padding: 8px 20px;
      font-size: 16px;
      border-radius: 10px;
      margin-right: 4px;
    }
  }
  .loading-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .items {
    display: flex;
    flex-direction: column;
    .item {
      background-color: white;
      border-radius: 10px;
      display: flex;
      margin-bottom: 20px;
      padding: 20px;
      .item-title {
        padding-right: 10px;
        display: flex;
        word-break: break-all;
        align-items: center;
        font-weight: 600;
        width: 20%;
      }
      .tags {
        width: 72%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;
        .tag {
          background-color: #e4e3e3;
          border-radius: 5px;
          padding: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          width: fit-content;
          height: fit-content;
        }
      }
      .buttons {
        width: 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
          margin-bottom: 20px;
          background-color: black;
          color: white;
          border: none;
          outline: none;
          width: fit-content;
          padding: 5px;
          border-radius: 5px;
          font-size: 32px;
        }
        .delete-button {
          background-color: #c90000;
        }
      }
    }
  }
}
