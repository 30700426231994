.margin-30 {
  margin: 30px;
  display: flex;
  flex-direction: column;
}
.Complaints_bottom {
  width: 100%;
  display: flex;
}
.Complaints_bottom_left {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Complaints {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px !important;
}
.title {
  margin-left: 50px;
  font-size: 32px;
  font-weight: 700;
}
