.custom-dashboard {
  display: flex;
  .logo {
    width: 310px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: black;

    .button-container {
      button {
        margin: 4px;
      }
    }
    img {
      background-color: white;
      padding: 10px;
      width: 70px;
      height: 70px;
      border-radius: 12px;
    }
  }
  .tabs {
    background-color: black;
    width: max-content;
    min-width: 310px;
    height: 100vh;
    overflow: scroll;
    .tab-wrapper {
      color: white;
      background-color: rgba(255, 255, 255, 0.2);

      margin: 8px;
      padding: 8px 12px;
      border-radius: 8px;
      border-bottom: 1px solid black;
      border-left: 3px solid rgb(255, 255, 255, 0.4);
    }
    .tab-active {
      .tab-wrapper {
        color: white;
        background-color: rgba(45, 154, 237, 0.666);
      }
    }
    .tab-inner-wrapper {
      margin-top: 120px;
      margin-bottom: 80px;
    }
  }
  .content {
    width: 100%;
  }
}
