.view-report-button {
  margin-top: 2px;
  color: #6c6c6c;
  border: none;
  outline: none;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  background-color: #f4f6f9;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
}
