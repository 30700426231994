.general-history-report-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    width: fit-content !important;
    min-width: 400px;
    padding: 15px 20px 20px 20px;
    border-radius: 20px;
  }
  .subheading {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .modal-open {
    overflow: scroll;
  }
  .header-row {
    display: flex;
    justify-content: space-between;
  }
  .modal-title {
    font-size: 32px;
    font-weight: 600;
    margin: 5px 0 10px 20px;
  }
  .close-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.view-history-button {
  margin-top: 2px;
  color: white;
  border: none;
  outline: none;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  background-color: #22548a;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
}
