.button_style {
  background: #379f8e;
  border-radius: 12px;
  color: white;
  width: 100%;
  height: 60px;
  border: 1px #379f8e;
  font-weight: 600;
  font-size: 18px;
}
