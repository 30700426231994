.list-dropdown-input .collection-li {
  padding: 8px 10px 8px 10px;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  margin: 4px 0px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.list-dropdown-input .collection-list {
  list-style-type: none;
  padding: 2px;
  margin-bottom: 5px;
}
.list-dropdown-input {
  border-radius: 4px;
  padding: 2px;
  margin-bottom: 5px;
}
.list-dropdown-input .collection-remove-button {
  border: none;
  padding: 0px;
  margin: 0px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  border-radius: 7px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  background-color: rgb(200, 0, 80);
  font-weight: bold;
  color: white;
  font-size: 8px;
  font-family: comic sans ms;
}
.list-dropdown-input select.minimal {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
