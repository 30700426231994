.infobox {
  padding-left: 5px;
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    color: rgb(121, 121, 121);
    color: rgb(21, 21, 21);
    font-weight: 500;
  }
  tr:nth-child(even) {
    background-color: white;
  }

  tr:hover {
    background-color: rgba(21, 51, 88, 0.3);
  }

  th {
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: white;
    color: rgb(16, 16, 16);

    line-height: 50px;
    font-weight: 600;
  }
}

.patient-info-box {
  max-width: 520px;
  // height: 350px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: black;
  margin-bottom: 10px;

  button {
    // width: 200px;
    margin-right: 5px;
  }
  .pib--name {
    margin: 20px 20px 10px 20px;
  }
  .pib--info-sec-box {
    margin: 5px 25px;
    font-size: 13px;
    display: inline-block;
    .pib--title {
      width: 50px;
      display: inline-block;
      font-weight: bold;
    }
    .pib--value {
      display: inline-block;
      width: 170px;
    }
  }
  .pib--history {
    text-decoration: underline;
    color: #fff;
    margin-left: 10%;
  }
  .subheading {
    display: inline-block;
    margin: 6px;
  }
  [data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    border-radius: 50%;
    background: plum;
    vertical-align: middle;
    margin-right: 10px;
    color: white;
  }
}
.requestButton {
  margin: 2%;
  margin-left: 30%;
}
.btn-box {
  margin: 20px;
}
// .modifyButton{
//   margin:3px;
// }
.qna-info-box-3 {
  width: 600px !important;
  background-color: #fff;
  padding: 20px 15px;
  border: 1px solid #ddd;
  margin-top: 10px;
  max-height: none;
  margin-left: 30px;
  overflow: visible;
  color: black;
  display: inline-block;
  vertical-align: top;
}
.qna-info-box-2 {
  width: 420px !important;
  background-color: #fff;
  padding: 20px 15px;
  border: 1px solid #ddd;
  margin-top: 10px;
  max-height: none;
  margin-left: 5px;
  overflow: visible;
  color: black;
  display: inline-block;
}
.qna-info-box {
  width: 520px !important;
  max-width: 100%;
  background-color: #fff;
  padding: 20px 15px;
  border: 1px solid #ddd;
  color: #444;
  margin-top: 10px;
  // max-height: 700px;
  overflow: auto;
  .qna--single-box {
    margin: 15px 10px;
  }
  .qna--question {
    font-weight: bold;
    font-size: 14px;
  }
  .qna--answer {
    font-weight: normal;
    font-size: 14px;
    .ans {
      font-weight: bold;
    }
  }
}

.column {
  float: left;
  width: 100%;
  padding: 5px;
}
.row::after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}
.imageContainer {
  padding-right: 50px;
  margin: 0px;
  width: 400px;
  height: 300px;
}
.qna {
  display: table;
  width: 500px;
  height: 400px;
  overflow: auto;
}
img {
  //padding-left: 25%;
  height: 250px;
  max-width: 400px;
  object-fit: contain;
}
.loadergif {
  margin-top: 15px;
  width: 93%;
}
// .imageContainer > img:hover {
//   width: 500px;
//   height: 500px;
// }

.error-call {
  color: orange;
  font-size: large;
}
.phone-icon {
  cursor: pointer;
}

.timer {
  border: 1px solid white;
  height: 10px;
  padding: 3.5px;
}
.sectionHeading {
  margin-bottom: 30px;
}
.sectionHeading::before {
  content: "\a0\a0\a0\a0\a0\a0\a0\a0";
  display: block;
  position: absolute;
  text-decoration: underline;
  width: 50px;
  overflow: hidden;
  margin-top: 10px;
}
.modal-content {
  width: 60% !important;
  color: black;
}

.phone-icon2 {
  cursor: pointer;
  width: 80px;
  margin-left: 3px;
  display: inline-block;
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  font-weight: 400;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s;
  box-sizing: border-box;
  text-align: center;
}
