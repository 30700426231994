.header-dropdown__name {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.4s ease-in;
  width: max-content;
}

.header-popup__top-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.header-popup__top-category__wrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px dashed rgba(128, 128, 128, 0.363);
  margin-bottom: 20px;
  padding-bottom: 2vh;

  .header-popup__top-category {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
  .header-popup__top-category__title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    opacity: 0.5;
    margin-right: 5px;
    white-space: nowrap;
  }
  .header-popup__top-category__detail {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    white-space: nowrap;
    opacity: 0.9;
    margin-left: 4px;
  }
}
.header-dropdown__name {
  cursor: pointer;
  .dropdown__name_icon {
    margin: auto;
    margin-left: 10px;
    font-size: 22px;
  }
}
.header-dropdown__wrapper {
  position: relative;
  .header-popup__wrapper {
    padding: 0;
    position: absolute;
    top: 30px;
    right: 0vh;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    button {
      border: 0;
      outline: none;
      padding: 4px 0;
      background-color: transparent;
      font-size: 14px;
      font-weight: 600;
      font-family: "Sofia Pro";
      color: rgba(0, 128, 128, 0.812);
      transition: all 0.2s ease-in-out;
      padding: 1rem 1rem 0.2rem 0.4rem;
      width: 100%;
      border: 0;
      text-align: start;

      &:hover {
        color: teal;
      }
      &:active {
        color: rgba(0, 128, 128, 0.521);
      }
    }
  }
}

.patient-detail {
  height: 80px;
  overflow: hidden;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;

  .subheading {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    opacity: 0.6;
    white-space: nowrap;
    margin-bottom: 3px;
  }
}
.displayNone {
  display: none;
}
.header-popup__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
  border-top: 2px dashed rgba(128, 128, 128, 0.363);
  h6 {
    font-family: "Sofia Pro";
    font-size: 14px;
    font-weight: 700;
  }
}
.header-popup__bottom__left {
  width: 50%;
  p {
    font-size: 14px;
    color: gray;
    margin: 0;
    line-height: 18px;
    font-family: "Sofia Pro";
  }
}
.header-popup__bottom__right {
  width: 35%;
  p {
    border: 1px solid gray;
    width: max-content;
    border-radius: 6px;
    padding: 0px 4px;
  }
  span {
    font-size: 14px;
    opacity: 0.9;
    margin: 0;
    line-height: 18px;
    font-family: "Sofia Pro";
    color: gray;
    letter-spacing: 0;
  }
  .header-popup__bottom__right__wrapper {
    display: flex;
    flex-direction: column;
    p {
      margin-right: 8px;
    }
  }
}
