.originalAppointment {
  border: 1px solid rgb(111, 169, 255);
  padding: 10px 20px;
  background-color: rgba(111, 169, 255, 0.15);
  border-radius: 3px;
  color: rgb(111, 169, 255);
  width: 100%;
  margin-bottom: 17px;
}
.anyDoctor {
  padding-bottom: 20px;
}

.paid-button-group {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.paid-button-group.paid-group {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.paid-button {
  outline: none;
  background-color: transparent;
  border: 2px solid black;
  color: black;
  margin: 0px 5px;
  border-radius: 4px;
  cursor: pointer;
}
.paid-button.active {
  background-color: black;
  color: white !important;
}
.paid-button.paid {
  border-color: rgb(0, 130, 200);
  color: rgb(0, 130, 200);
}
.paid-button.paid.active {
  background-color: rgb(0, 130, 200);
}
.paid-button.free {
  border-color: rgb(0, 180, 120);
  color: rgb(0, 180, 120);
}
.paid-button.free.active {
  background-color: rgb(0, 180, 120);
}
.paid-button-group .brand-filter-button {
  margin: 0px 5px !important;
}
