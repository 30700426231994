.view-all-updates-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 20px;
    padding: 20px;
    width: 70% !important;
    background-color: #f9fafb;
  }
  .close-button {
    width: 30px;
    height: 30px;
    // top:5px;
    cursor: pointer;
  }
  .modal-title {
    font-size: 32px;
    font-weight: 600;
    margin: 5px 0 10px 20px;
  }
  .modal-data-container {
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 20px;
    padding-top: 10px;
    border-radius: 20px;
    .remarkBox {
      margin-top: 10px;
    }
    .remarkStatus {
      font-weight: 600;
      font-size: 18px;
      .remark {
        padding-left: 0px;
        width: 100%;
        font-weight: 500;
        opacity: 0.7;
      }
    }
    .remark-date {
      opacity: 0.7;
      font-weight: 500;
    }
    .remark-time {
      font-weight: 500;
      opacity: 0.7;
    }
  }
}
