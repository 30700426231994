.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-title {
  font-weight: 400;
  font-size: 20px;
  font-family: "Sofia Pro";
}

.input-box {
  width: 100%;
  margin-top: 10px;
  outline: none;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  padding: 10px;
}
.selected-presets {
  margin: 5px;
  display: flex;
  flex-direction: row;
}

.preset-style {
  background: rgb(240, 242, 246);
  border-radius: 10px;
  color: "darkgrey";
  margin: 5px;
  padding: 5px;
}
.parent {
  padding: 10px;
  border-radius: 12px;
}
#demo-multiple-checkbox {
}
