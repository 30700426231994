.otherProducts__product_container {
  display: flex;
  width: 100%;
  margin: 20px 0;
  padding: 14px 8px;
  justify-content: space-between;
  min-width: 100% !important;
  box-sizing: border-box;
  font-family: "Sofia Pro";
  button {
    border: none;
    margin: 4px;
    padding: 6px;
    border-radius: 6px;
  }
  .product__instructions_input {
    width: 100%;
  }
  .product__instructions_left {
    width: 80%;
  }
  input {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    margin: 4px 4px;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 16px;
  }
  p {
    margin: 0px 0;
  }
  .OP_product__container_item {
    width: 30%;
    box-sizing: border-box;
  }
  .product__days {
    width: 15%;
    display: flex;
    justify-content: flex-start;
  }
  .product__days_input {
    width: 60px;
    height: 40px;
  }
  .product__instructions {
    width: 55%;
    display: flex;
    justify-content: space-between;
    .product__instructions_right {
      display: flex;
      flex-direction: column;
    }
  }
}

.OSC-deleteIcon:hover {
  color: #e86669;
  background: #fff !important;
}
.OSC-editIcon:hover {
  color: #22548a !important;
  background: #fff !important;
}
