.quickAccess {
  width: 100%;
  @rem z-index: 2;
  .quickAccess__btn {
    min-width: 97px;
    min-height: 36px;
    outline: none;
    border: 1.5px solid rgba(47, 82, 135, 1);
    background: rgba(47, 82, 135, 1);
    color: white;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 4px 12px;
    z-index: 2;
    right: 5%;
    position: fixed;
    top: 100px;
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.2);
    &:hover {
      color: white;
    }
    &:active {
      opacity: 0.8;
    }
  }
  &__container__parent {
    overflow: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.05rem solid #f6f6f6;
    margin: 30px;
    border-radius: 15px;
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
    background: white;
    width: 96%;
    top: 70px;
    z-index: 8;
    height: max-content;
  }
  &__container {
    padding: 20px 30px 20px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: no-wrap;
    box-sizing: border-box;
    width: 100%;
    .crossIcon {
      position: absolute;
      cursor: pointer;
      right: 8px;
      top: 8px;
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    div {
      display: flex;
      box-sizing: border-box;
    }
  }
  &__left {
    max-width: 800px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
    }
    h4 {
      margin: 0;
    }
    .quickAccess__left_nameAge {
      margin-right: 20px;
      h4 {
        font-size: 1.4rem;
        font-weight: 600;
        margin: auto;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      span {
        margin: 0 10px;
        width: 2px;
        height: 24px;
        background: black;
      }
      p {
        font-weight: 600;
        font-size: 1.4rem;
        margin: auto;
        line-height: 0;
      }
    }
    .quickAccess__left_icon {
      width: 2rem;
      height: 2rem;
      background: rgba(91, 180, 127, 0.4);
      padding: 0.3rem;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .quickAccess__left_category {
      box-sizing: border-box;
      p {
        cursor: pointer;
      }
      p:nth-child(2) {
        padding: 4px 14px;
        border-radius: 12px;
        color: #d77325;
        border: 1px solid #d77325;
        margin: 0 6px;
      }
      p:nth-child(1) {
        padding: 4px 14px;
        border-radius: 12px;
        margin: 0 6px;
        color: #5194d1;
        border: 1px solid #5194d1;
      }
    }
    .quickAccess__left_maritalStatus {
      display: flex;
      align-items: center;
      p {
        color: rgba(0, 0, 0, 0.6);
      }
      span {
        margin-left: 14px;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .quickAccess__left_city {
      display: flex;
      align-items: center;
      p {
        color: rgba(0, 0, 0, 0.6);
      }
      span {
        margin-left: 14px;
        font-weight: 700;
        font-size: 18px;
        width: 100px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
  &__right {
    display: flex;
    max-width: 600px;
    margin: auto;
    justify-content: flex-start;
    button {
      min-height: 36px;
      padding: 0 10px;
      outline: none;
      color: gray;
      border: none;
      border-radius: 12px;
      white-space: nowrap !important;
      transition: all 0.3s ease;
    }
    div {
      margin-left: 14px;
    }
  }
}
.quickAccess-popup__bottom {
  margin-right: auto;
  padding: 20px 30px 20px 30px;
  display: flex !important;
  width: 100%;
  border-top: 2px dashed rgba(128, 128, 128, 0.363);
  height: max-content;
  &_subParent {
    margin-right: 2rem;
    h6 {
      font-family: "Sofia Pro";
      font-size: 14px;
      color: gray;
      font-weight: 700;
    }
    div {
      max-width: 200px;
      display: flex;
      flex-wrap: wrap;
      span {
        font-weight: 600;
      }
    }
  }
}
