@import "../../../_base.scss";
.bmi {
  display: flex;
  align-items: center;
  .weight-height-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-right: 20px;
    .subheading {
      font-weight: 600;
      font-size: 18px;
      margin-left: 5px;
      margin-bottom: 10px;
    }
    input {
      border: none;
      outline: none;
      border-radius: 10px;
      width: 150px;
      padding: 8px 10px;
      background-color: #f6f6f6;
    }
    select {
      padding: 8px 30px 8px 14px;
      width: 100%;
      background: $select-background-color;
      border-radius: 12px;
      outline: 0;
      border: 0;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("../../../asset/selectIcon.svg");
      background-position: right;
      background-repeat: no-repeat;
      background-size: 32px 7px;
      option:nth-child(1) {
        display: none;
      }
      option {
        cursor: pointer;
        color: $option-color;
        font-weight: $option-font-weight;
      }
    }
  }
  .bmi-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    padding-left: 20px;
    .bmi-subheading {
      font-weight: 600;
      font-size: 18px;
    }
    .bmi-value {
      font-size: 24px;
      font-weight: 600;
      transform: translate(0, 12px);
      p {
        line-height: 0;
        margin: 0;
      }
    }
  }
}
