.active {
  color: burlywood !important;
}

.dashboard-page-container {
  display: flex;
}
.dashboard-page-container > div:nth-child(1) {
  min-height: 100vh;
}
.dashboard-page-container > div:nth-child(2) {
  flex: 1 1 0;
  margin-left: 5px;
  margin-top: 0;
}
