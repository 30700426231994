.SelfAssesmentForm {
  border: 2px solid #f6f6f6;
  overflow-x: hidden;
  margin: 30px 0;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: fit-content;
  .title {
    font-weight: 600;
    font-size: 32px;
    margin: 0px 0 10px 15px;
  }
  .tag-row {
    display: flex;
    padding-left: 10px;
    .selectedTag {
      margin-left: 5px;
      margin-right: 5px;
      font-weight: 500;
      font-size: 12px;
      color: #9a521c;
      cursor: pointer;
      border-radius: 6px;
      width: fit-content;
      padding: 6px 10px 6px 10px;
      background-color: #fef3ea;
    }
    .tags {
      margin-left: 5px;
      margin-right: 5px;
      font-weight: 400;
      font-size: 12px;
      color: #6c6c6c;
      cursor: pointer;
      border-radius: 6px;
      width: fit-content;
      padding: 6px 10px 6px 10px;
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }
  .qna {
    height: fit-content;
    margin-left: 0px;
    .question {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-left: 15px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .answer {
      font-size: 16px;
      line-height: 22px;
      margin-left: 15px;
      margin-bottom: 20px;
      display: flex;
      .ans {
        font-weight: 600;
        margin-right: 3px;
      }
    }
    .divider {
      margin-left: 15px;
      margin-right: 20px;
    }
  }
}
.selfAssessment__questionAnswer {
  margin-top: 10px;
}
