.quick-view-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 2px;
  border: 1px solid rgb(220, 220, 220);
}
.quick-view-container .quick-view-main-container {
  display: flex;
  justify-content: space-between;
}
.quick-view-container .field {
  font-size: 14px;
  font-weight: bold;
}
.quick-view-container .value {
  font-size: 14px;
  padding: 0px 10px 0px 4px;
}
.quick-view-container .totals {
}
