.prescription-pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.prescription-pdf-title {
  font-size: 32px;
  font-weight: 600;
  margin: 30px 0;
}
.prescription-pdf-iframe {
  margin-bottom: 10px;
}
