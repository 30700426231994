.mealinfo {
  width: 500px;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px;
  padding: 0;
  width: 45%;
  float: left;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}
.mealinfo-container {
  border: none;
  padding: 20px;
}
.dropdownChild2 p {
  float: left;
  padding: 15px 0;
  color: red;
  font-weight: 900;
  font-size: 24px;
}
// .status-info-box {
//     width: 500px;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     padding: 20px;

// .status-drop {
//     margin: 10px 0;
//   }
//   .sib--st-dd {
//     width: 440px;
//     // width: 500px;
//     background-color: #fff;
//     border: 1px solid #ddd;
//     padding: 20px;

//   }
// }
.status-drop {
  margin: 10px 0;
}
.sib--st-dd {
  width: 440px;
  // width: 500px;
  background-color: #fff;
  // border: 1px solid #ddd;
  padding: 20px;
}
.sectionHeading {
  margin-bottom: 30px;
}
.sectionHeading::before {
  content: "\a0\a0\a0\a0\a0\a0\a0\a0";
  display: block;
  position: absolute;
  text-decoration: underline;
  width: 50px;
  overflow: hidden;
  margin-top: 10px;
}

textarea {
  outline-color: #0169d9;
}
