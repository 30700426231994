@import "../../../_base.scss";
.prescribed-products {
  padding-left: 20px;
  margin-bottom: 60px;
}
.pp-title {
  @include Card__Title;
}
.table-header {
  border: 1px solid #cecaca;
  border-radius: 18px;
  padding: 10px 15px;
  padding-right: 25px;
  margin-bottom: 20px;
}
.table-body-item {
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  select {
    padding: 0px 10px;
    background: rgba(0, 0, 0, 0.05);
    font-weight: 500;
    border-radius: 12px;
    outline: 0;
    height: 35px;
    width: 100px;
    border: 0;

    cursor: pointer;
    option:nth-child(1) {
      display: none;
    }
    option {
      cursor: pointer;
    }
  }
}
