.viewImages__button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .medical-history-button_ {
    outline: none !important;
    border: none;
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 14px;
    background-color: #22548a;
    border-radius: 10px;
    padding: 7px 15px 7px 15px;
    width: max-content !important;
    white-spacing: nowrap !important;
    height: 40px;
  }
}
.view-images-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 20px;
  }
}
