.yourPerformance {
  padding: 10px 10px 20px;
  background-color: rgb(231, 238, 235);
  font-weight: 600;
  font-size: calc(14px + 0.2vw);
}

.yourPerformance > div {
  border-radius: 8px;
  background-color: white;
  margin-top: 20px;
  box-shadow: 0 0 5px 5px grey;
  display: grid;
  padding: 25px 0 25px 9px;
  color: blue;
  grid-template-columns: repeat(auto-fit, minmax(16.66%, 1fr));
}

.yourPerformance > div > div > :last-child {
  font-size: 25px;
  font-weight: bolder;
}

.yourPerformance > div > div:nth-child(1) > :last-child {
  color: green;
}
.yourPerformance > div > div:nth-child(2) > :last-child {
  color: rgb(190, 190, 17);
}
.yourPerformance > div > div:nth-child(3) > :last-child {
  color: rgb(73, 158, 73);
}
.yourPerformance > div > div:nth-child(4) > :last-child {
  color: blue;
}
.yourPerformance > div > div:nth-child(5) > :last-child {
  color: rgb(160, 5, 160);
}
.yourPerformance > div > div:nth-child(6) > :last-child {
  color: rgb(160, 5, 160);
}
