.prescription-report {
  .prescription-row {
    display: flex;
    justify-content: space-between;
    width: fit-content !important;
    align-items: center;
    margin-bottom: 15px;
    .subheading {
      font-size: 16px;
      font-weight: 600;
      margin-right: 10px;
    }
    .val {
      display: flex;
      margin-right: 20px;
    }
    .download-button {
      margin-top: 2px;
      margin-left: 50px;
      color: #22548a;
      border: none;
      outline: none;
      width: fit-content;
      font-weight: 600;
      font-size: 14px;
      background-color: #f4f6f9;
      border-radius: 10px;
      padding: 7px 15px 7px 15px;
    }
  }
  .header-row {
    display: flex;
    justify-content: space-between;
  }
  .modal-open {
    overflow: scroll;
  }
  .modal-title {
    font-size: 32px;
    font-weight: 600;
    margin: 5px 0 10px 20px;
  }
  .close-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
