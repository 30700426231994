.patient-history {
  width: 100%;
}
.patient-history .toggle-bar {
  padding: 10px 20px;
  background-color: rgb(40, 40, 40);
  color: white;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgb(40, 40, 40);
}
.history-main-container {
  background-color: white;
  padding: 5px;
  // transition-property: all;
  // transition-duration: 500ms;
  box-shadow: 2px 2px 8px rgb(200, 200, 200);
  max-height: 400px;
  overflow-y: auto;
}
.history-card {
  margin: 10px 5px;
  border: 1px solid rgb(240, 240, 240);
  padding: 15px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 9px rgb(220, 220, 220);
  font-size: 14px;
  line-height: 22px;
  color: rgb(70, 70, 70);
}
.history-card-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.history-card-show-button {
  background-color: #f5faf9;
  border: none;
  outline: none !important;
  width: fit-content;
  // position: absolute;
  // right: 25px;
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 10px;
}
.history-card .highlight {
  font-weight: 600;
}
.history-date-container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(200, 200, 200);
}
.history-date-and-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(200, 200, 200);
  margin-bottom: 15px;
}
.order-placed .history-card-title {
  color: rgb(0, 200, 190);
}
.order-placed .history-card-show-button {
  color: rgb(0, 200, 190);
}
.form-filled .history-card-title {
  color: rgb(110, 40, 0);
}
.form-filled .history-card-show-button {
  color: rgb(110, 40, 0);
}
.form-submitted .history-card-title {
  color: rgb(0, 30, 120);
}
.form-submitted .history-card-show-button {
  color: rgb(0, 30, 120);
}
.appointment-rescheduled .history-card-title {
  color: rgb(150, 0, 120);
}
.appointment-rescheduled .history-card-show-button {
  color: rgb(150, 0, 120);
}
.appointment-booked .history-card-title {
  color: rgb(0, 150, 240);
}
.appointment-booked .history-card-show-button {
  color: rgb(0, 150, 240);
}
.prescription-generated .history-card-title {
  color: rgb(200, 100, 0);
}
.prescription-generated .history-card-show-button {
  color: rgb(200, 100, 0);
}
.patient-history .history-card button {
  width: initial;
}
.history-pdf-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 20px;
    padding: 20px;
  }
}
