.root {
  min-width: 275;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-around {
  justify-content: space-around;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
}
.RxWriterScreenBody {
  padding-top: 20vh;
  position: relative;
  z-index: 1;
}
.rxwriter-loader-container {
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  font-family: "Sofia Pro";
}
