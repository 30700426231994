.main-recommendation-container {
  margin-bottom: 20px;
  display: flex;
  width: 900px;
  flex-direction: column;
  select {
    border: none;
    outline: none;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .product-row {
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    .search-product {
      width: 80% !important;
      outline: none;
      border: none;
      border-radius: 10px;
      padding: 8px 5px;
    }
    .product-button {
      border-radius: 8px;
      width: 15%;
      padding: 7px 0;
      border: none;
      outline: none;
      font-weight: 500;
      color: white;
    }
    .recommend {
      background-color: #0085ff;
    }
    .restrict {
      background-color: #a33b3b;
    }
  }
  .recommended-products {
    padding: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: white;
  }
}
.main-recommnedation-search-combobox {
  width: 750px !important;
}
