.download-toggle .toggler-button {
  box-shadow: none !important;
  background-color: #144166 !important;
  border: 1px solid #144166 !important;
}
.download-toggle .download-list-wrapper {
  width: 0px;
  height: 0px;
  overflow: visible;
  z-index: 20000;
}
.download-toggle .download-list {
  border-radius: 4px;
  border: 1px solid rgb(240, 240, 240);
  z-index: 20000;
  width: max-content;
  background-color: white;
  position: absolute;
  right: 10px;
  margin: 2px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(220, 220, 220);
}
.download-toggle .download-button {
  padding: 10px;
  border-bottom: 1px solid rgb(245, 245, 245);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
