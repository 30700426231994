.header-buttons__wrapper_a > button {
  height: 40px;
  border-radius: 12px;
  border: solid;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 8px;
  width: 200px;
  .header_buttons__dropdown-icon {
    width: 20px;
    color: black;
    font-size: 18px;
  }
}

.header_status_options-container {
  position: absolute;
  top: 30px;
  left: 0px;
  background-color: white;
  border: 1.8px solid #6c6c6ca9;
  border-top: 0;
  border-radius: 0 0 12px 12px;
  max-height: 280px;
  .header_status_options-container__content {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    width: max-content;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
  }

  width: 200px;
  .header_status_option {
    padding: 6px 10px 6px 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    font-size: 14px;
    &:hover {
      background-color: rgba(128, 128, 128, 0.062);
    }
  }
}
.header-buttons__wrapper_a {
  width: max-content;
  display: flex;
  align-items: center;
  position: relative;
  .header-buttons__viewAllUpdates {
    position: absolute;
    bottom: 0;
    color: white;
    width: 100%;
    background-color: #22548a;
    padding: 8px;
    border-radius: 0px 0 8px 8px;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: #22548af3;
    }
    &:active {
      background-color: #22548a;
    }
  }
}
.other_status_buttons {
  color: gray !important;
}
.pending_status_buttons {
  color: red !important;
}
.complete_status_buttons {
  color: green !important;
}
.header-buttons__wrapper_a {
  position: relative !important;
}
