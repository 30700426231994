.margin-30 {
  margin: 30px;
}
.Timeline {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
}
.Timeline-inner {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  width: 50%;
}
.Appoint_content {
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: space-evenly;
}
.Appoint_content_left {
  width: 49%;
}
.Appoint_content_left_top {
  div {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    p {
      margin: 2px 0;
      font-size: 16px;
      font-weight: 600;
    }
    span {
      width: 80%;
    }
  }
}
.Appoint_content_left_bottom {
  margin-top: 20px;

  button {
    margin-top: 10px;
    width: 90%;
    background: rgb(35, 85, 138);
    border: 0;
    border-radius: 10px;
    padding: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    pointer: cursor;
  }
}
.TimelineTitle {
  font-weight: 600;
  font-size: 30px;
  text-align: left;
  color: black;
  font-family: "Sofia Pro";
}
.view-prescription-modal {
  justify-content: center;
  display: flex;
  .modal-content {
    min-width: 400px;
    border-radius: 20px;
    padding: 20px;
    font-family: "Sofia Pro";
  }
}
.Timeline__Card {
  margin: 0 20px;
  min-height: 302px;
  height: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  font-family: "Sofia Pro";
}
.treatmentType__container {
  margin: 6px 0;
  min-height: 60px;
}
.RxSent__Container {
  margin: 6px 0;
  height: 100px;
  width: 100%;
  display: flex;
}

.PatientOrder__Container {
  margin: 6px 0;
  height: 130px;
}
.TimelineCard {
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  background: rgba(246, 246, 246, 1);
  box-shadow: 0 0 4px 8px rgba(0, 0, 0, 0.01);
  padding: 20px 0px;
  margin: 0 20px;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 290px;
  min-height: 314px;
  display: flex;
  flex-direction: column;
  .row {
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 10px;
    .left {
      width: 30%;
      font-weight: 600;
      font-size: 14px;
    }
    .right {
      width: 68%;
      font-size: 14px;
      letter-spacing: 0.02px;
      span {
        font-size: 13px;
        margin: 0px 0 0 0;
        line-height: 13px;
      }
    }
  }
  button {
    width: 90%;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    background: rgba(34, 84, 138, 1);
    outline: none;
    color: #fff;
    height: 37px;
  }
}
