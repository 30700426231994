.preset-builder-container {
  background-color: #c4c4c4;
  padding: 10px 20px;
  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      font-size: 50px;
      font-weight: 500;
      margin-left: 0;
    }
  }
}
.tag-input {
  padding: 5px;
  outline: none !important;
  background-color: white;
  border: none !important;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}
.input-tags {
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 10px 0;
  padding: 5px 10px;
}
.update-presets {
  width: 250px;
  color: white;
  font-weight: 500;
  background-color: black;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 0;
}
.checkboxes {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  width: 40%;
  .checkbox-row {
    display: flex;
    align-items: center;
  }
}
.delete-popover {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.popover-button {
  outline: none;
  border: none;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  margin: 5px;
}
.yes-button {
  background-color: red;
}
.no-button {
  background-color: green;
}
.brands {
  margin: 20px 0 0 0;
  .brand-value {
    margin-left: 20px;
    margin-right: 5px;
  }
}
