.pci {
  .MuiPaper-root {
    border-radius: 20px;
  }

  .MuiToolbar-root {
    background: #22548a;
    color: #fff;
    border-radius: 20px 20px 0 0;
  }

  .MuiButtonBase-root {
    color: #fff;
  }
}
.brandDropDown {
  display: flex;
  justify-content: end;
  margin: -50px 50px 50px;
  align-items: baseline;

  label {
    margin-right: 20px;
    color: #22548a;
    font-weight: bold;
  }

  button {
    background: #fff !important;
    color: #000 !important;
    width: 150px;
  }
}
