.mealinfo {
  width: 500px;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px;
  padding: 0;
  width: 45%;
  float: left;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  .form-qna {
    height: 440px;
    overflow: auto;
  }
}
.mealinfo-container {
  border: none;
  padding: 20px;
  overflow: auto;
}
.phone-icon2 {
  cursor: pointer;
  width: 80px;
  margin-left: 3px;
  display: inline-block;
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  font-weight: 400;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s;
  box-sizing: border-box;
  text-align: center;
}
.sectionHeading {
  margin-bottom: 30px;
}
.sectionHeading::before {
  content: "\a0\a0\a0\a0\a0\a0\a0\a0";
  display: block;
  position: absolute;
  text-decoration: underline;
  width: 50px;
  overflow: hidden;
  margin-top: 10px;
}

.sectionLabel {
  font-weight: 900;
  font-size: 14px;
}
