.preview-prescription-button {
  padding: 10px 25px;
  margin-left: 20px;
  border-radius: 12px;
  border: none;
  outline: none !important;
  font-size: 16px;
  color: white;
  font-weight: 600;
  background-color: #379f8e;
  font-family: "Sofia Pro";
}
