.search-product-container {
  @rem box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  @rem padding: 20px;
  @rem border-radius: 30px;
  @rem margin-bottom: 20px;
  .search-product-body {
    padding: 20px;
    .subheading {
      font-size: 18px;
      opacity: 0.7;
      margin-bottom: 20px;
    }
    .search-combobox {
      outline: none !important;
      width: 50%;
      border-radius: 15px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}
