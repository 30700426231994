.header-search {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 162px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2px 4px 2px 6px;
  height: 36px;
  font-family: "Sofia Pro";
  margin-right: 10px;
  input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
  .Header_searchIcon {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
