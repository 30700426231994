h3 {
  margin: 10px;
}
.item input {
  width: 300px;
  padding: 5px;
}
.sec-box {
  margin-top: 10px;
  .title {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0;
  }
}
.kit-item-box {
  border: 1px solid #adb4e6;
  border-radius: 4px;
  margin: 10px 5px;
  padding: 10px;
}
.dd {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #ddd;
  border-radius: 3px;
  border-collapse: collapse;
}
