.margin-30 {
  margin: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.AppointmentDetails {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  .appointment-details-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: "Sofia Pro";
    .subheading {
      font-weight: 600;
      font-family: "Sofia Pro";
    }
    .val {
      margin-right: 70px;
      margin-left: 10px;
      font-family: "Sofia Pro";
    }
    .follow-up-val {
      text-align: center;
      color: #22548a;
      font-weight: 600;
      padding: 10px;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
      width: 60px;
      background-color: #f4f6f9;
      outline: none !important;
      border: none;
      font-family: "Sofia Pro";
    }
    select {
      font-family: "Sofia Pro";
      padding: 13px 10px;
      background: rgba(0, 0, 0, 0.05);
      font-weight: 600;
      border-radius: 12px;
      outline: 0;
      width: 120px;
      color: #22548a;
      border: 0;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("../../asset/selectIcon.svg");
      background-position: right;
      background-repeat: no-repeat;
      background-size: 32px 7px;
      option:nth-child(1) {
        display: none;
      }
      option {
        cursor: pointer;
        font-weight: 600;
        border: none;
        font-family: "Sofia Pro";
      }
    }
  }
}
.AppointmentDetails_title {
  margin-left: 50px;
  font-size: 32px;
  font-weight: 700;
  font-family: "Sofia Pro";
}
