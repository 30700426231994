.new-recommendation-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    background-color: #c4c4c4 !important;
    border-radius: 20px;
    padding: 20px;
  }
  .is-adding {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    min-width: 900px;
    display: flex;
    justify-content: space-between;
    .new-concern {
      width: 85%;
      border: none;
      outline: none;
      padding: 10px;
      border-radius: 10px;
      height: 40px;
      background-color: white;
    }
    .done-button {
      color: white;
      border: none;
      outline: none;
      background-color: black;
      padding: 8px 30px;
      border-radius: 5px;
    }
    .done-button:disabled {
      background-color: #404040;
    }
  }
  .new-condition {
    color: white;
    border: none;
    outline: none;
    width: 100%;
    margin-bottom: 20px;
    background-color: #404040;
    padding: 15px;
    border-radius: 8px;
  }
  .submit {
    color: white;
    border: none;
    outline: none;
    margin-bottom: 20px;
    background-color: black;
    padding: 8px 30px;
    border-radius: 5px;
  }
  .cancel {
    background-color: #8e8e8e;
    margin-right: 20px;
  }
}
.add-new-rule {
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  background-color: black;
}
