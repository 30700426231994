.first-condition-container {
  border-radius: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  width: 900px;
  flex-direction: column;
  .num {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    outline: none;
    width: 40px;
    margin-right: 10px;
  }
  .comparator {
    margin-right: 10px;
    border: none;
    outline: none;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .time-period {
    margin-right: 10px;
    border: none;
    outline: none;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .type {
    font-weight: 500;
    max-width: 30%;
    margin-right: 20px;
  }
  .improvement {
    border: none;
    outline: none;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .select-preset {
    border: none;
    outline: none;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .product-row {
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    .first-condition-search-combobox {
      width: 575px !important;
    }
    .product-button {
      border-radius: 8px;
      width: 15%;
      padding: 7px 0;
      border: none;
      outline: none;
      font-weight: 500;
      color: white;
    }
    .recommend {
      background-color: #0085ff;
    }
    .restrict {
      background-color: #a33b3b;
    }
  }
  .recommended-products {
    padding: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: white;
  }
}
