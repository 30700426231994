.reassign-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 20px;
    padding: 20px;
  }
}
.reassign-modal__unauthorized-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 65px;
}
.reassign-modal__unauthorized-modal__icon-section {
  margin-bottom: 60px;
}
.reassign-modal__unauthorized-modal__icon {
  width: 118px;
  height: 106px;
  filter: drop-shadow(0px 16px 32.9824px rgba(242, 118, 121, 0.33));
}
.reassign-modal__unauthorized-modal__message {
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #959595;
  max-width: 384px;
}
