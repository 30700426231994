.scheduler-info-box {
  width: 500px;
  height: 200px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #444;
  margin: 10px 0;
  padding: 20px;
  .set-schedule-box {
    margin: 10px 0;
  }
  .btn-update {
    display: inline-block;
    margin: 0 30px;
    font-size: 14px !important;
  }
  .cal-out-datetime {
    font-size: 14px;
  }
  .cal-inp {
    width: 300px;
  }
  .react-datepicker__tab-loop {
    display: inline-block !important;
  }
  .sib--seperator {
    margin: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .sib--send-sms-box {
    margin: 0 auto;
    text-align: center;
  }
  .calendar-btn {
    margin: 0 10px;
  }
}
