.image-timeline-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    min-width: 400px;
    border-radius: 20px;
  }
}
.view-images-button {
  margin-top: 2px;
  color: white;
  border: none;
  outline: none;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  background-color: #22548a;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
}
.quickview-images-button {
  margin-top: 2px;
  color: #22548a;
  border: none;
  outline: none;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  background-color: #f4f6f9;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
  white-space: nowrap !important;
  border: 1px solid #22548a;
  //make transition of color smooth
  transition: all 0.15s linear;
}
.quickview-images-button:hover {
  background-color: #22548a;
  color: white;
}
