.RecommendedProducts {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
}
.RecommendedProducts__title {
  margin-left: 50px;
  font-size: 32px;
  font-weight: 700;
  font-family: "Sofia Pro";
}
.RecommendedProducts__subtitle {
  margin-left: 50px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Sofia Pro";
}
