.recommendation-engine-container {
  .header-row {
    margin-top: 30px;
    display: flex;
    .builder {
      border-radius: 10px 10px 0 0;
      border: none;
      outline: none;
      width: 230px;
      padding: 15px 0 8px 0;
      margin-right: 10px;
      display: flex;
      justify-content: center;
    }
    .selected {
      background-color: #c4c4c4;
      font-weight: 600;
    }
  }
}
