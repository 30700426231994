.sendVideoLink__send-video-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.sendVideoLink__button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sendVideoLink__send-video-link-button {
  background-color: #f4f6f9;
  color: #22548a;
  transition: all 0.5s linear;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  padding: 7px 15px 7px 15px;
  margin: 0;
  width: max-content !important;
  white-spacing: nowrap !important;
  height: 40px;
}
.sendVideoLink__send-video-link-button:disabled {
  background-color: #f4f6f9;
  color: #6c6c6c;
}
.request-sent-text {
  margin-top: 10px;
  font-weight: 600;
  color: #379f8e;
}
