.container-box {
  display: flex;
  justify-content: space-between;
  padding: 25px 10px;
}

.top-container {
  width: 98%;
  margin: 1%;
  .rowC {
    max-width: 1100px;
    width: 85%;
    margin: 0 auto;
  }
}

/* .row {
  display: block;
  padding: 10px;
  
} */
.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.categoryDropdown {
  width: 125px;
}
.categoryDropdown .react-dropdown-select-dropdown {
  width: 100%;
}
.remarksDropdown .react-dropdown-select-dropdown {
  width: 100%;
}
.usi-inp {
  width: 150px;
  height: 40px;
  margin-right: 10px;
}
.child1 {
  // height: 500px;
  margin-top: 30px;
}
.child1 .left-child {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #ddd;
  padding: 10px;
}
.child1 .right-child {
  width: 67%;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  border: 1px solid #ddd;
  padding: 10px;
}
.child1 .shining-right-child {
  width: 67%;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  // border: 1px solid #ddd;
  background-color: white;
  padding: 10px;
}
.child2 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.child2-r {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.edit-box {
  width: 300px;
}
.btn-s {
  margin-left: 5px;
}
.action-box {
  margin-left: 0%;
}
.action-box .btn {
  margin-left: 10px;
}
.label-inp {
  font-size: 12px;
}

.container-box2 textarea {
  width: 450px;
}
.diag-box {
  margin: 30px 10px;
}
.modal-dialog {
  max-width: 850px !important;
}
.remark {
  //float: left; width: 200px;
  font-size: 14px;
  padding-right: 5px;
  padding-left: 5px;
  width: 70%;
}
.remarkStatus {
  //float: left; width: 200px;
  font-weight: bold;
  float: left;
  width: 60%;
  padding-left: 5px;
  font-size: 14px;
}
.timeStamp {
  float: right;
  font-size: 12px;
  width: 100%;
  text-align: end;
}

.status-box {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin-left: 5px;
}
.status-info-box {
  width: 500px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  .status-drop {
    margin: 10px 0;
  }
  .sib--st-dd {
    width: 440px;
  }
  .ssib--st-dd {
    width: 100%;
  }
  .remarkBox {
    margin: 10px;
    padding-bottom: 10px;
  }
  .timestamp {
    font-size: 12px;
    display: inline-block;
    font-style: italic;
  }
  .sib--remark-ta {
    width: 400px;
    color: #444;
    border: 1px solid #ddd;
  }
  .sib--remark-btn-box {
    margin: 20px 0;
    .btn {
      width: 100%;
    }
  }
  .sib--activity-logs {
    border-radius: 3px;
    background-color: #eef1ef;
    padding: 15px;

    .sib--activity-box {
      max-height: 185px;
      overflow-y: scroll;
    }
    .sib--no-log {
      font-size: 12px;
      color: #444;
      text-align: center;
      margin: 20px;
    }
  }
}
.observations-info-box {
  width: 500px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-top: 20px;
  padding: 20px;
  .oib--ta {
    width: 400px;
    border: 1px solid #ddd;
    color: #444;
  }
  .oib--inp-box {
    margin: 10px 0;
  }
}
.sweet-alert-box {
  text-align: center;
}

// .float-container {
//   border: 3px solid #fff;
//   padding: 20px;
// }

// .float-child1 {
//   width: 50%;
//   float: left;
//   padding: 20px;
//   border: 2px solid red;
// }

// .float-child2 {
//   width: 50%;
//   float: left;
//   padding: 20px;
//   border: 2px solid red;
// }

.rightchild {
  border: 1px solid #ddd;
  padding: 4px;
}
.prescriptionPage-container {
  background: #e5e5e5;
  min-height: 100vh;
  color: black;
}
.dropdown-toggle {
  height: 47px;
}
.formLabel {
  margin: 5px 0;
}
/* Spinner implementation */
.lds-ring {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.app-installed-label {
  padding: 8px 10px;
  background-color: rgb(0, 158, 105);
  color: white;
  width: max-content;
  height: max-content;
  margin: 0px 10px;
  border-radius: 4px;
  font-weight: 600;
}
