.list-input .collection-li {
  padding: 8px 10px 8px 10px;
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  margin: 4px 0px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.list-input .collection-li div {
  max-width: 95%;
  word-wrap: break-word;
}
.list-input .collection-list {
  list-style-type: none;
  padding: 2px 0px;
  margin: 2px 0px;
}
.list-input {
  padding: 2px;
  margin-bottom: 5px;
}
.list-input .collection-remove-button {
  border: none;
  padding: 0px;
  margin: 0px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  border-radius: 7px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  background-color: rgb(200, 0, 80);
  font-weight: bold;
  color: white;
  font-size: 8px;
  font-family: comic sans ms;
  float: left;
}
