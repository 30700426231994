.xyz {
  position: absolute !important;
  z-index: 3000 !important;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.v-btn {
  margin: 3px;
}
.warn-msg {
  color: #666;
  text-align: center;
  font-size: 14px;
  margin: 40px;
}
.xyz .modal-content {
  width: 850px !important;
}
.loader-cm {
  text-align: center;
  font-size: 14px;
}
.Loader-box {
  width: 50px;
  display: inline-block;
  margin-left: 20px;
}
.xyz .modal-footer {
  justify-content: flex-start !important;
}
.inln-blk {
  display: inline-block;
}
.col-ins {
  display: inline-block;
  vertical-align: middle;
  margin-right: 50px;
  width: 80%;
}
