.header-text__wrapper {
  display: flex;
  justify-content: space-between;
  flex: 0.38;
  div {
    font-family: "Sofia Pro";
  }
}

.header-text {
  font-size: 14px;
  font-weight: 600;
  color: #22548a;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in;
  padding: 5px 8px;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 6px;
  border: 1.8px solid transparent;
  a {
    color: inherit;
  }
}

.header-text:hover {
  border: 1.8px solid #22548a;
  background: rgba(34, 84, 138, 0.08);
}
