.title {
  margin-left: 50px;
  font-size: 32px;
  font-weight: 700;
  font-family: "Sofia Pro";
}
.recommendation-item {
  margin-bottom: 20px;
}
.margin-30 {
  border-radius: 30px;
  padding: 30px 30px 15px 30px;
  border: 1px solid #f1f2f3;
}
.RecommendationsForLifestyle {
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1);
  font-family: "Sofia Pro";
}
