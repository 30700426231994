$option-color: rgba(34, 84, 138, 1);
$select-background-color: rgba(246, 246, 246, 1);
$option-font-weight: 600;
@mixin Card__Title() {
  font-weight: 600;
  font-size: 24px;
}
@mixin Line_Clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@mixin Remove_Arrow_Inside_Input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
