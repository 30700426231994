.pop-overlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.popup-container {
  max-width: 600px;
  width: 60%;
  min-height: 150px;
  border: 1px solid #666;
  z-index: 999;
  background-color: #eee;
  margin: 80px auto;
  color: #666;
  border-radius: 5px;
  padding: 10px;
  .popup-type {
    margin: 5px 20px;
    font-size: 14px;
    overflow-wrap: break-word;
  }
  .popup-message {
    font-size: 22px;
    margin: 5px 20px;
    color: #444;
    overflow-wrap: break-word;
  }
  .p-action-box {
    display: flex;
    justify-content: right;
    flex-direction: row-reverse;
    .pbtn {
      margin: 10px;
      width: 100px;
    }
  }
}
