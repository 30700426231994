body {
  font-family: sans-serif;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.parentDoctorAi {
  display: "flex";
  flex-direction: "column-reverse";
  height: 100%;
  width: 100%;

  .annotation__container {
    height: 90%;
    overflow: scroll;
    // margin: 5px;
    width: 100%;
  }

  .deleteButton {
    display: "flex";
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .buttonSpace {
    display: "flex";
    flex-direction: "column-reverse";
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: clip;
    height: 10%;
    width: 100%;

    .dropdown {
      margin: 10px;
    }

    .docbtn {
      align-items: center;
      background-color: #ffffff;
      background-color: #22548a;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
      box-sizing: border-box;
      // color: rgba(0, 0, 0, 0.85);
      color: white;
      cursor: pointer;
      display: center;
      font-family: system-ui, -apple-system, system-ui, "Helvetica Neue",
        Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin: 5px;
      min-height: 3rem;
      padding: 12px;
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: auto;

      .dropdownlists {
        display: flex;
        flex-direction: "column-reverse";
        justify-content: center;
        line-height: 1.25;
        margin: 10px;
      }
    }
    .docbtn:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .docbtn:hover,
    .docbtn:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }

    .docbtn:hover {
      transform: translateY(-1px);
    }

    .docbtn:active {
      background-color: #f0f0f1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    }
  }
}
