.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Sofia Pro";
  .next-button {
    background-color: #379f8e;
    color: white;
    font-weight: 600;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 8px 45px 8px 45px;
    width: fit-content;
    margin-bottom: 80px;
    transition: all 0.2s linear;
  }
  .next-button:disabled {
    background-color: #ebeced;
    color: #6c6c6c;
  }
}
